<template>
    <div class="page-layout-wrapper">
        <div class="container">
            <PageHeader
                title="All Users"
            />
            <div v-if="loading">
                Loading Users...
            </div>
            <div v-else>
                <table class="table">
                    <thead>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th />
                    </thead>
                    <tbody>
                        <tr
                            v-for="(user, i) in users"
                            :key="i"
                        >
                            <td v-text="user.id" />
                            <td v-text="user.name" />
                            <td v-text="user.email" />
                            <td v-text="user.role_key" />
                            <td>
                                <router-link class="pill-button" :to="`/admin/users/${user.id}`">
                                    Edit
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from '@/components/Page/PageHeader'

export default {
    name: 'Users',
    components: {
        PageHeader
    },
    data: () => ({
        loading: false,
        users: [],
    }),
    created() {
        this.loading = true
        this.$http
            .get('/admin/users')
            .then(({ data }) => {
                this.loading = false
                this.users = data.users
            })
    }
}
</script>
