<template>
  <div class="page-header">
    <h1 class="title" v-text="title" />
    <div v-if="subtitle" v-text="subtitle" />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: [String, Boolean],
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.page-header {
  text-align: center;
  margin-bottom: 3rem;
}

.title {
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: -0.03em;
}
</style>
